import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';
import i18n from "@/core/plugins/i18n";

//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import Toast, { PluginOptions } from "vue-toastification";
import AudioPlayer from '@liripeng/vue-audio-player'
import VueVideoPlayer from '@videojs-player/vue'

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import firebase from "firebase"
import 'firebase/app'
import "firebase/firestore"
import "firebase/storage"

const app = createApp(App);

let fb_app = firebase.initializeApp({
    apiKey: "AIzaSyBsGE_0E1ODa_ZsSJyQVVl5QoxUsaMn604",
    authDomain: "mahala-airtime.firebaseapp.com",
    projectId: "mahala-airtime",
    storageBucket: "mahala-airtime.appspot.com",
    messagingSenderId: "402405232219",
    appId: "1:402405232219:web:a75d29b7ec2914f82bb97e",
    measurementId: "G-N4PYXTF8V1"
})
firebase.analytics(fb_app)

app.use(store);
app.use(router)
app.use(Toast), {
    shareAppContext: true,
};
app.use(ElementPlus);
app.use(AudioPlayer)
app.use(VueVideoPlayer)

ApiService.init(app);
// MockAdapter.init(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
